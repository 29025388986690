/**
 * Merchant prospect status parameters
 */
export enum EMerchantProspectStatus {
  CREATED = 'created',
  OFFER_SENT = 'offer_sent',
  SIGNED = 'signed',
  SUBMITTED = 'submitted',
  IN_REVIEW = 'in_review',
  NEEDS_MORE_INFO = 'needs_more_info',
  APPROVED = 'approved',
  DECLINED = 'declined',
  WITHDRAWN = 'withdrawn',
}

/**
 * Merchant status parameters
 */
export enum EMerchantStatus {
  ACTIVE = 'active',
  CLOSED = 'closed',
}

/**
 * Merchant onboarding status parameters
 */
export enum EMerchantOnboardingStatus {
  DRAFT = 'draft',
  COMPLETED = 'completed',
  DENIED = 'denied',
}
